<template>
  <div>
    <!-- MAIN LOADER -->
    <template v-if="loader && !campaign">
        <Loader
          :size="'big'"
        />
    </template> <!-- END MAIN LOADER -->
    <template v-else-if="currentPackage">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center mb-5">
        <div class="col-lg-7">
          <h1 class="highlighted-color1 font-size-large wrap-text">
            <span>{{ currentPackage.name }}</span>
          </h1>
        </div>
        <div class="d-flex">
          <div id="edit-package-wrap">
            <router-link
              :to="{ name: 'packages.edit', params: { cId: campaign.id, id: currentPackage.id } }"
              class="btn btn-sm btn-primary me-2"
              :class="{
                disabled: isCampaignPaused
              }"
              v-if="campaign.status !== 'FINISHED' && campaign.status !== 'DRAFT'"
            >
              {{ $t('edit_group') }}
            </router-link>
          </div>
          <b-tooltip
            target="edit-package-wrap"
            v-if="isCampaignPaused"
          >
            {{ $t('editing_a_campaign_group_is_not_allowed') }}
          </b-tooltip>
          <template v-if="campaign.status !== 'FINISHED'">
            <template v-if="currentPackage.status === 'ACTIVE'">
              <Button
                id="pause-package-button"
                class="btn-sm btn-color1 me-2"
                @click="openPauseCampaignPackageModal"
                :softDisabled="isCampaignPaused"
              >
                {{ $t('pause') }}
              </Button>
              <b-tooltip
                target="pause-package-button"
                v-if="isCampaignPaused"
              >
                {{ $t('not_allowed_to_pause_a_campaign_group') }}
              </b-tooltip>
              <PauseCampaignPackageModal
                :campaign="campaign"
                :segmentation="currentPackage"
                @package-paused="getCampaign"
              />
            </template>
            <template v-else-if="currentPackage.status === 'PAUSED'">
              <Button
                id="activate-package-button"
                class="btn-sm btn-color1 me-2"
                @click="openActivateCampaignPackageModal"
                :softDisabled="isCampaignPaused"
              >
                {{ $t('enable') }}
              </Button>
              <b-tooltip
                target="activate-package-button"
                v-if="isCampaignPaused"
              >
                {{ $t('not_allowed_to_pause_a_campaign_group') }}
              </b-tooltip>
              <ActivateCampaignPackageModal
                :campaign="campaign"
                :segmentation="currentPackage"
                @package-activated="getCampaign"
              />
            </template>
          </template>
          <router-link
            :to="{ name: 'campaigns.show', params: { id: campaign.id } }"
            class="btn btn-sm btn-primary"
          >
            {{ $t('back') }}
          </router-link>
        </div>
      </div> <!-- END HEADER -->
      <!-- MESSAGES -->
      <div
        class="mb-4"
        v-if="currentPackage.status === 'ERROR' || currentPackage.status === 'PAUSED'"
      >
        <div
          class="error-message-card text-white font-size-small p-3"
          v-if="currentPackage.status === 'ERROR'"
        >
          <p
            class="mb-0"
          >
            {{ $t('error_creating_campaign_group') }}
            <span>
              <RetryCampaignPackageCreationOnPlatformButton
                :packageId="currentPackage.id"
                @retry-successful="getCampaign"
                @retry-error="handleRetryError"
                color="white"
              />
            </span>
          </p>
        </div>
        <div
          class="error-message-card text-white font-size-small p-3"
          v-if="currentPackage.status === 'PAUSED'"
        >
          <p
            class="mb-0"
          >
            {{ $t('this_campaign_group_is_paused') }}
          </p>
        </div>
      </div>
      <div class="mb-4">
        <div
          class="alert-message-card font-size-small p-3"
          v-if="currentPackage.amount === 0 || (!currentPackage.start_date && !currentPackage.end_date) "
        >
          <p
            class="mb-0 fw-700"
          >
            {{ $t('unfilled_fields') }}
          </p>
        </div>
      </div><!-- END MESSAGES -->
      <!-- OVERVIEW -->
      <div class="mb-5">
        <h2 class="highlighted-color1 font-size-medium mb-4">
          <span>{{ $t('general_data') }}</span>
        </h2>
        <div class="card">
          <div class="card-body">
            <p>
              <strong>{{ $t('name') }}:</strong> {{ currentPackage.name }}<br/>
              <strong>{{ $t('start') }}:</strong> <span v-if="currentPackage.start_date">{{ currentPackage.start_date | formatDate }}</span><span v-else>{{ $t('not_defined') }}</span><br/>
              <strong>{{ $t('end') }}:</strong> <span v-if="currentPackage.end_date">{{ currentPackage.end_date | formatDate }}</span><span v-else>{{ $t('not_defined') }}</span><br/>
              <strong>{{ $t('cpm_goal') }}:</strong> {{ currentPackage.cpm_target_amount / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
              <strong>{{ $t('cpc_goal') }}:</strong> {{ currentPackage.cpc_target_amount / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
              <strong>{{ $t('objective') }}:</strong> {{ currentPackage.objective | formatObjective }}<br/>
              <strong>{{ $t('status') }}:</strong> {{ currentPackage.status | formatCampaignStatus }}<br/>
              <strong>{{ $t('budget') }}:</strong> {{ currentPackage.amount / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
              <strong>{{ $t('spending_to_date') }}:</strong> {{ currentPackage.spending / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
              <strong>{{ $t('total_impressions') }}:</strong> {{ currentPackage.impressions | formatNumber }}<br/>
              <strong>{{ $t('total_clicks') }}:</strong> {{ currentPackage.clicks | formatNumber }}<br/>
              <strong>{{ $t('total_conversions') }}:</strong> {{ currentPackage.conversions | formatNumber }}<br/>
              <strong>{{ $t('ctr') }}:</strong> {{ currentPackage.ctr | formatNumber }}%<br/>
              <strong>{{ $t('cpm') }}:</strong> {{ currentPackage.cpm / 100 | formatMoney(campaign.advertiser.currency) }}<br/>
              <strong>{{ $t('cpa') }}:</strong> {{ currentPackage.cpa / 100 | formatMoney(campaign.advertiser.currency) }}
            </p>
          </div>
        </div>
      </div> <!-- END OVERVIEW -->
      <!-- LINES -->
      <div>
        <!-- HEADER -->
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div>
            <h2 class="highlighted-color1">
              <span>{{ $t('segmentation_strategies') }}</span>  <span v-b-tooltip.hover :title="tooltips.lines"><i class="fa fa-info-circle font-size-medium"></i></span>
            </h2>
          </div>
          <div>
            <router-link
              :to="{ name: 'lines.create', params: { cId: campaign.id, pId: currentPackage.id } }"
              class="btn btn-color1"
              v-if="currentPackage.status === 'ACTIVE' || currentPackage.status === 'PAUSED'"
            >
              {{ $t('create_strategy') }}
            </router-link>
          </div>
        </div> <!-- END HEADER -->
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('name') }}</th>
                    <th>{{ $t('type') }}</th>
                    <th>{{ $t('status') }}</th>
                    <th>{{ $t('spending') }}</th>
                    <th>{{ $t('impressions') }}</th>
                    <th>{{ $t('clicks') }}</th>
                    <th>{{ $t('conversions') }}</th>
                    <th>{{ $t('ctr') }}</th>
                    <th>{{ $t('cpm') }}</th>
                    <th>{{ $t('cpc') }}</th>
                    <th>{{ $t('cpa') }}</th>
                    <th>{{ $t('actions') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(line, index) in currentPackage.lines"
                    :key="index"
                    class="text-nowrap"
                  >
                    <td class="text-nowrap">
                      <router-link
                        :to="{ name: 'lines.show', params: { cId: campaign.id, id: line.id } }"
                        class="link-color3"
                      >
                        <span :title="line.name">{{ line.name.length > 40 ? `${line.name.substring(0, 40)}...` : line.name }}</span>
                      </router-link>
                    </td>
                    <td>{{ line.type | formatCreativeFormat }}</td>
                    <td>{{ line.status | formatCampaignStatus }}</td>
                    <td>{{ line.spending / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                    <td>{{ line.impressions | formatNumber }}</td>
                    <td>{{ line.clicks | formatNumber }}</td>
                    <td>{{ line.conversions | formatNumber }}</td>
                    <td>{{ line.ctr }}%</td>
                    <td>{{ line.cpm / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                    <td>{{ line.cpc / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                    <td>{{ line.cpa / 100 | formatMoney(campaign.advertiser.currency) }}</td>
                    <td>
                      <router-link
                        :to="{ name: 'lines.show', params: { cId: campaign.id, id: line.id } }"
                        class="link-color3 me-2"
                        v-b-tooltip.hover :title="$t('see_more')"
                      >
                        <i class="fa fa-eye"></i>
                      </router-link>
                      <router-link
                        :to="{ name: 'lines.create', params: { cId: campaign.id, pId: currentPackage.id }, query: { replicate: line.id } }"
                        class="link-color3"
                        v-b-tooltip.hover :title="$t('duplicate_strategy')"
                        target="_blank"
                      >
                        <i class="fa fa-copy"></i>
                      </router-link>
                      <span v-if="(currentPackage.status !== 'PENDING' && currentPackage.status !== 'ERROR') && (line.status === 'PENDING' || line.status === 'ERROR')" class="px-2">
                        <RetryCampaignLineCreationOnPlatformButton
                          :lineId="line.id"
                          @retry-successful="getCampaign"
                          @retry-error="handleRetryError"
                          color="color5"
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> <!-- END LINES -->
    </template>
    <NewCampaignPackageModal
      :campaign="campaign"
      :segmentation="currentPackage"
    />
  </div>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { mapActions, mapState } from 'vuex'
import variables from '@/mixins/variables'

import ActivateCampaignPackageModal from '@/components/campaigns/ActivateCampaignPackageModal'
import Button from '@/components/common/Button/Button'
import NewCampaignPackageModal from '@/components/campaigns/NewCampaignPackageModal'
import Loader from '@/components/common/Loader/Loader'
import PauseCampaignPackageModal from '@/components/campaigns/PauseCampaignPackageModal'
import RetryCampaignPackageCreationOnPlatformButton from '@/components/campaigns/RetryCampaignPackageCreationOnPlatformButton'
import RetryCampaignLineCreationOnPlatformButton from '@/components/campaigns/RetryCampaignLineCreationOnPlatformButton'

export default {
  mixins: [variables],
  components: {
    ActivateCampaignPackageModal,
    Button,
    NewCampaignPackageModal,
    Loader,
    PauseCampaignPackageModal,
    RetryCampaignPackageCreationOnPlatformButton,
    RetryCampaignLineCreationOnPlatformButton
  },
  data () {
    return {
      campaign: null,
      loader: false
    }
  },
  metaInfo () {
    return {
      title: this.currentPackage ? this.currentPackage.name : ''
    }
  },
  created () {
    if (this.storeCampaign) {
      this.campaign = this.storeCampaign
      this.showModal()
    } else this.getCampaign()
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    ...mapState('campaigns', {
      storeCampaign: state => state.currentCampaign
    }),
    currentPackage () {
      if (!this.campaign) return null
      return this.campaign.packages.find(item => {
        return item.id === this.$route.params.id
      })
    },
    /**
     * Is campaign paused
     */
    isCampaignPaused () {
      if (!this.campaign) return false
      return this.campaign.status === 'PAUSED'
    }
  },
  methods: {
    ...mapActions('campaigns', [
      'setCurrentCampaign'
    ]),
    /**
     * Get campaign
     */
    async getCampaign () {
      this.loader = true
      try {
        const campaign = await CampaignsService.getCampaign(this.$route.params.cId)
        this.campaign = campaign
        this.setCurrentCampaign({ campaign: campaign })
        this.showModal()
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
        this.$router.push('/')
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Open activate campaign package modal
     */
    openActivateCampaignPackageModal () {
      this.$bvModal.show('activate-campaign-package-modal')
    },
    /**
     * Open pause campaign package modal
     */
    openPauseCampaignPackageModal () {
      this.$bvModal.show('pause-campaign-package-modal')
    },
    /**
     * Open replicate line modal
     */
    openReplicateLineModal (line) {},
    /**
     * Show new package modal
     */
    showModal () {
      setTimeout(() => {
        const seg = this.campaign.packages.find(item => {
          return item.id === this.$route.params.id
        })
        if (!seg) return
        if (seg.lines.length === 0 && this.currentPackage.status !== 'ERROR') this.$bvModal.show('new-campaign-package-modal')
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-text {
  width: 100%;
  word-wrap: break-word;
}
</style>
